import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
import { getEntityTableColumns } from "@/utils/enums";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      if (this.small) {
        query.page_size = 5;
      }
      return this.$store.dispatch(this.action, {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      let columns = [{
        title: "№",
        name: "index",
        width: 50,
        type: "number"
      }, {
        title: "Поисковый запрос",
        name: "keyword",
        //"123456789 123456789 123456789 123 567",
        width: 300,
        filter: "text",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "KeywordInfo",
              params: {
                k_id: item.k_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keyword,
            target: "_blank"
          };
        }
      }, {
        title: "Частотность " + (this.reportSettings.mp === "wb" ? "WB" : "OZON"),
        name: "shows",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, ...getEntityTableColumns("keyword", 3, this.reportSettings.metric)].map((col, i) => {
        col.position = i + 1;
        col.show = true;
        if (!col.width) col.width = 100;
        return col;
      });
      if (this.small) {
        columns = columns.filter(item => item.name === "keyword" || item.name === "revenue");
      }
      return columns;
    }
  }
};